import { Container, Row, Col } from "react-bootstrap";
import logo from './image/planet.png';
import navIcon1 from './image/facebook.png';
import navIcon2 from './image/whatsapp.png';
import navIcon3 from './image/instagram.png';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
          <div className="d-flex">
        <img src={logo} style={{width: '7vh', marginRight: "10px"}} />
                <a class="navbar-brand" href="/" style={{fontSize:'3.5vh', fontWeight: 'bold'}}><div class="colorful-text">
    <span className="span">L</span>
    <span className="span">M</span>
    <span className="span">C</span>
    <span className="span">N</span>
    <span className="span">O</span>
    <span className="span">v</span>
    <span className="span">A</span>
  </div></a>
        </div>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="#"><img src={navIcon1} alt="Icon" /></a>
              <a href="#"><img src={navIcon2} alt="Icon" /></a>
              <a href="#"><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p>Copyright 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}