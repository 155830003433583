// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavbarUser, { NavBarUser } from './navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Banner } from './Banner';
import { Projects } from './Project';
import { Contact } from './Contact';
import { Footer } from './Footer';



const App = () => {
  return (
    <>
    <NavBarUser />
    <Banner />
    <Projects />
    <Contact />
    <Footer />
    </>
    
  );
};

export default App;
